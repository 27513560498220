// extracted by mini-css-extract-plugin
export var wrap = "cart-module--wrap--1mWUB";
export var cartheader = "cart-module--cartheader--13uzQ";
export var cartfooter = "cart-module--cartfooter--ZtqMK";
export var summary = "cart-module--summary--3DX23";
export var title = "cart-module--title--3qvMZ";
export var emptytitle = "cart-module--emptytitle--16JLF";
export var font = "cart-module--font--1-pOV";
export var clearfix = "cart-module--clearfix--1CwDR";
export var buttoncolor = "cart-module--buttoncolor--xXb7d";
export var pcolor = "cart-module--pcolor--2_4ZW";